@import "../vendor/bootstrap/less/bootstrap";
@import "../vendor/font-awesome/less/font-awesome";

@import (reference)  "variables";

.ir { display: block; border: 0; text-indent: -999em; overflow: hidden; background-color: transparent; background-repeat: no-repeat; text-align: left; direction: ltr; }
.ir br { display: none; }
.visuallyhidden {border: 0; clip: rect(0 0 0 0); height: 1px; margin: -1px; overflow: hidden; padding: 0; position: absolute; width: 1px; }
.visuallyhidden.focusable:active, .visuallyhidden.focusable:focus { clip: auto; height: auto; margin: 0; overflow: visible; position: static; width: auto; }
.invisible { visibility: hidden; }

.mt-1 {margin-top: @line-height-computed * 1px;}
.mt-2 {margin-top: @line-height-computed * 2px;}
.mt-3 {margin-top: @line-height-computed * 3px;}

.mb-1 {margin-bottom: @line-height-computed * 1px;}
.mb-2 {margin-bottom: @line-height-computed * 2px;}
.mb-3 {margin-bottom: @line-height-computed * 3px;}

.navbar-default .navbar-nav > .open > a, .navbar-default .navbar-nav > .open > a:hover, .navbar-default .navbar-nav > .open > a:focus {
	background-color: #776d55;
	color: #555555;
}
.navbar-default .navbar-toggle:hover, .navbar-default .navbar-toggle:focus {
	background-color: #ad3e2d;
}
.navbar-toggle:focus{
	border:none;
}
.navbar-default .navbar-toggle {
	border-color: #ad3e2d;
}
.navbar-default .navbar-toggle .icon-bar {
  background-color: #f5e3b5;
}

.navbar-brand {
	height: auto;
}

// Floats
.float-right {
	float: right;
	margin: 0 0 @line-height-computed @line-height-computed;
	clear: right; // This ensures images don't 'bump' into each other
}

.float-left {
	float: left;
	margin: 0 @line-height-computed @line-height-computed 0;
	clear: left; // This ensures images don't 'bump' into each other
}

.float-none {
	float: none; // if anyone knows why these are "important", let me know
	margin: 0;
}

// Useful for image link hover states
.transparent (@filter-opacity, @opacity) {
	zoom: 1;
	filter: alpha(opacity=@filter-opacity);
	opacity: @opacity;
}

/**	----------------------------------------
/**	Environment Indicator
/**	----------------------------------------*/
.env-indicator {
	position: fixed;
	bottom: 0;
	right: 0;
	z-index: 99;
	color: #fff;
	border-radius: 5px 0 0 0;
	padding: 2px 5px;
	font-size: 11px;
}

.env-indicator.env-dev {
	background: @state-success-text;
}

.env-indicator.env-staging {
	background: @state-warning-text;
}


// Grids with 1px borders between them, Ryan loves using these.
.col-bdr {
	outline-right: 1px solid #d5d5d5;
}

.col-bdl {
	outline-left: 1px solid #d5d5d5;
}

.col-bdb {
	outline-bottom: 1px solid #d5d5d5;
}

.btn-edit-this {
	.btn;
	.btn-default;
	.glyphicon;
	.glyphicon-pencil;
	padding: 5px 6px;
}


/**	----------------------------------------
/** STRUCTURE
/**	1. Layout & Structure
/**	2. Modular components
/**	3. Page Specific
/**	----------------------------------------

/**	----------------------------------------
/**	3. LAYOUT & STRUCTURE
/** Page structures & layout
/**	----------------------------------------*/
h1,h2,h3,h4,h5{
	color:#e06900;
}
h2{
	font-size: 18px;
	text-transform:uppercase;
}
html,body {
  height: 100%;
  position: relative;
}

#wrap {
	min-height: 100%;
	height: auto;
	/* Negative indent footer by its height */
	margin: 0 auto;
	/* Pad bottom by footer height */
	padding: 0 0;
}

#header {
	// margin: 0 0 20px;
	background: #776d55;
}

#header-logo {
	background: url('../images/pascar-final-logo.png') no-repeat;
	display: block;
	float: left;
	width: 105px;
	height: 105px;
	margin: 10px;
	text-indent:-999999px;
}

.page-banner{
	width:100%;
	background: url('../images/page-banner.jpg') repeat;
	height:102px;
}
 .banner{ 
	  // background: url('../images/main-banner.jpg') repeat-x center center; 
	  background-size: cover;
		background-size:contain;
	  -webkit-background-size: cover;
	  -moz-background-size: cover;
	  -o-background-size: cover;
}
/*Footer */

#footer {
	background: #444444;
	margin-top: 0px;
	width: 100%;

	.footer-whois {
		text-align: center;
		margin: 18px 0;
		color:#ffffff;
		a{
			color:#ffffff;
		}
	}
}
/*back to top*/
#back-top{
	position:absolute;
	top:20px; 
	right:15px; 
	i{
		color:#ffffff;
		background-color:#444444;
		padding:17px;
	}
}
#bottom-strip{
	margin-top:76px;
	background-color: #ededed;
	width:100%;
	height:40px;
}
#contact-footer{
	padding-bottom:44px;
	background-color: #776d55;
	width:100%;
	h3{
		color:#ffffff;
		font-size:14px;
		font-weight:bold;
		margin-top:30px;
	}
	p{
		color:#ffffff;
		font-size:12px;
	}
	.logo{
		margin-top:30px;
	}
	.ft-readmore{
		color:#ffffff;
		padding:10px;
		border: 1px solid #ffffff;
		line-height:4em;
		text-transform:uppercase;
		font-size:11px;
	}
	.ln-wrapper{
		float:left;
	}
	.linkedin{
		margin-left:10px;
	}
}

#main {
}

.navbar-default {
	background: none;
	position: relative;
	margin-top: 0px;
	margin-bottom: 0px;
	font-family: @font-family-serif;
	font-weight: 200;
	border:none;
}
.navbar-default .navbar-nav > li > a {
	color: #ffffff;
	font-size:16px;
}

.navbar-default .navbar-nav>.active_parent>a, 
.navbar-default .navbar-nav>.active_parent>a:focus, 
.navbar-default .navbar-nav>.active_parent>a:hover {
	color: @navbar-default-link-active-color;
}

.navbar-search {
	margin-top: 50px;
}

.navbar-primary {
	margin-top: 50px;
    font-weight: bold;
}

.navbar-taskforces.navbar-nav  {
	max-width: 560px;

	> li > a {
		font-size: 13px;
		padding-top: 5px;
		padding-bottom: 5px;
	}

	@media (min-width: @screen-md) {
		max-width: 800px;
	}
}

.nav > li > a {
	position: relative;
	display: block;
	padding: 10px 13px;
}
.search-box-form{
	.input-group-btn{
		width:20px;
		.btn-default{
			border-color:#ffffff;
			background-color:none;
		}
	}
}
.fa-circle{color:#e06900;}
.fa-stack{font-size:2.5em;}
.fa{
	&.fa-search{
		margin-top:-3px;
		color: #ffffff;
		border: 1px solid #ffffff;
		padding: 7px;
		border-radius: 30px;
			z-index:150;
	}
}

.dropdown{
	&.animate-click{
		.dropdown-menu{
			-webkit-box-shadow: none;
			-moz-box-shadow: none;
			box-shadow: none;
		}
	}
}
.navbar-right .dropdown-menu {
	right: 58px;
	left: auto;
}

.dropdown-menu{
	&.dropdown-menu-user{
		background:none !important;
		border:0;
	}
}
#breadcrumb{
	background-color:#333333;
	padding-top:5px;
	padding-bottom:5px;
	.breadcrumb{
		color:#ffffff;
		text-transform: uppercase;
		font-weight:200;
		a{
			color:#ffffff;
			font-weight:bold;
			text-transform: uppercase;
		}
	}
}
/*Sidebar - nav*/
.nav-pills > li.active > a, .nav-pills > li.active > a:hover, .nav-pills > li.active > a:focus {
	color: #e06900;
	background-color: #dcdcdc;
}
.nav-pills > li > a {
border-radius: 0px;
}
.grey-block{
	background:#ededed;
}
.super-heading{
	background:#ededed;
	padding:0 40px;
	h1{
		font-size:14px;
		text-transform:uppercase;
		font-size:14px;
		padding-top:30px;
	}	
}
.contact-us{
	h1{
		color:#333333;
		font-size:20px;
		font-weight:bold;
		text-transform:uppercase;
	}
}
.panel {
	border: 0px;
	// padding-bottom:50px;
	.panel-heading,.panel-title{
		background:#ededed;
		padding: 10px 0px;
		text-transform: uppercase;
		font-weight: 900;
	}

	.panel-body {
		background:#ededed;
		padding: 15px 40px;
		position: relative;
		top: -2px;
		li{
			border-bottom:1px solid #cbcbcb;
		}
	}
}
.become-member{
	text-align:center;
	margin-top:30px;
	h2{font-size:20px;}
}
/**	----------------------------------------
/**	2. Modular components
/** Modular blocks & re-usable components
/**	----------------------------------------*/

// Media Cntd.
// ------------------------------------
.media {
	border-bottom: 1px solid @gray-lighter;

	&:last-child {
		border-bottom: 0;
	}
}

// Metadata at the bottom of an article/page
// ------------------------------------
.meta {
	display: block;
	font-size: 12px;

	border-top: 1px solid @gray-lighter;
	border-top-color: #efefef;
	border-bottom: 1px solid @gray-lighter;

	ul {
		margin: 0;
		list-style: none;
		float: right;
	}
	li {
		float: left;
		text-align: right;
		margin: 5px 0 5px 0;
		padding: 0 2px;
	}

	.fb-like {
		width: 80px;
	}
	.twitter-share-button {
		width: 95px;
	}
	.twitter-share-button, .fb-like, .meta-cat {
		padding-top: 5px;
	}
	.cat {
		margin-top: 11px;

		&:before {
			content: '';
			.glyphicon;
			.glyphicon-tag;
			display: inline-block;
			margin-right: 5px;
		}
	}
}
#breadcrumb {
	// margin-bottom: 20px;
	.breadcrumb {
		padding: 0;
		background-color: transparent;

		&:last-child {
			margin-bottom: 0;
		}
	}
}



h1:first-child {
	margin-top: 0;
}

.page-heading {
	margin-bottom: @font-size-base * @line-height-base * 2px;
	h1 {
	}
	.media-meta {
	}
}


// Metadata at the top of an article/page ie. published date
// ------------------------------------
.media-meta {
	list-style: none;
	padding: 0;

	margin-bottom: @line-height-base;
	font-size: @font-size-small;
	color: @gray;

	.date {
		font-weight: bold;
	}

	li {
		display: block;
		float: left;
		padding-right: 20px;
	}
	.addthis_toolbox {
		width: 120%;
		.addthis_counter.addthis_bubble_style {
			width: 36px !important;
		}
	}
	.clearfix;
}

/* SEARCH BUTTONS */

#submit {
	background: url('../img/new-search-icon.png');
	width: 20px;
	height: 20px;
	border: none;
}


.listing_grid {
	li {
		margin-right: 20px;
		margin-bottom: 20px;
	}
}

/*Search Page*/
.search{
	.form-inline{
		margin-top:0px;
	}
	.media{padding-bottom:15px;}
}

// Landing page framework
// -------------------------
.grid_item {

	&.border_top {
		margin-top: 0;
		padding-top: 9px;
		border-top: 1px solid #d5d5d5;
	}

	&.border_bottom {
		margin-bottom: 0;
		padding-bottom: 9px;
		border-bottom: 1px solid #d5d5d5;
	}

	&.border_left {
		border-left: 1px solid #d5d5d5;
		padding-left: 9px;
		margin-left: 0;
	}

	&.border_right {
		border-right: 1px solid #d5d5d5;
		margin-right: 0;
		padding-right: 9px;
	}

	&.bg_rounded {
		//.border-radius(4px);
	}

	&.bg_tint {

		.box {
			margin-bottom: 20px;
		}

	}

}

// Video container for inline videos
// -------------------------
.video-container {
	position: relative;
	padding-bottom: 56.25%;
	padding-top: 30px;
	height: 0;
	overflow: hidden;

	iframe, object, embed {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}

// Articles
// -------------------------

.article, .article-content {
	// Test baseline
	// background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAHklEQVQYlWP8//8/AzGAiShVowoJAUYGBgZJYhQCANiGAykLdyIpAAAAAElFTkSuQmCC);

	// .baseLineType(14px, 20px);
	.panel{
		.panel-heading{
			padding:10px 15px;
		}
	}
}


.horizontal-list-items {
	margin: 10px auto;
	display: table-cell;
	height: 170px;
	vertical-align: middle;
	text-align: center;
	img {
		vertical-align: baseline;
	}
	p {
		margin-bottom: 5px;
	}
}
// Images
// -------------------------

.img {

}

.article-image {
  padding:0; // We need to override the grid padding on images, e.g. <img class="img article-image col-sm-6">
  margin-top:4px; // adjust to ensure top of image in line with top of text

	.caption {
		margin-top: 5px;
		font-weight: bold;
		display: block;
		margin-bottom: 10px;

		font-size: @font-size-small;

		p {
			margin-bottom: 0;
			line-height: 13px;
		}
	}
}

.image-group-grid {

	.col-sm-6:nth-child(odd) {
		padding-left:0;
	}
	.col-sm-6:nth-child(even) {
		padding-right:0;
	}
	.clearfix;

}



// Read more buttons
// -------------------------
.readmore {
	.label;
	font-size: @font-size-small;
	background: @gray-dark;
	padding: 2px 3px;

	&:hover {
		background-color: darken(@gray-dark, 10%);
		text-decoration: none;
		color: white;
	}

	// Reverse out if on dark colour
	&.inverse {
		background: #fff;
		color: @gray-dark;
	}

	&.inverse:hover {
		color: @gray-darker;
	}
}

.file-download {
	margin: 0 !important;
	li {
		width: 180px;
		margin-right: 20px;
		margin-bottom: 20px;
		text-align: center;
		background: @gray-light;
		border: 1px solid #CCC;
		padding: 5px;

		img {
			margin: 0 auto;
			display: block;
		}
		.pdf-title {
			font-size: 14px;
			font-family: @headings-font-family;
			font-weight: 700;

			.pdf-icon {
				background: url('../img/pdf-icon.png');
				width: 15px;
				height: 20px;
				margin-right: 5px;
				display: inline-block;
				margin-bottom: -3px;
			}
		}
	}
}


// Pull quotes

.panel.pullquote {
  .quote {
	font-weight:bold;
	display:block;
  }
  .attribution {
	font-style:oblique;
	display:block;
  }

}

// Site Map
// ----------------------
.site-map {
	.site-map-list {
		li {
			width: 80px;
			margin-right: 20px;
			font-size: 20px;
			a {
				color: @text-color;
			}
		}
		li.last_child {
			margin-right: 0;
		}
	}
	.site-map-list-secondary {
		li {
			margin-top: 10px;
			font-size: 16px;
			a {
				color: @gray;
			}
		}
	}
}

/**	----------------------------------------
/**	3. PAGE SPECIFIC
/** Non-modular, non-repeatable elements
/**	----------------------------------------*/

.choices {

	> div:nth-child(odd) {
		background-color: #EFEFEF !important;
	}

	> div:nth-child(even) {
		background-color: #80CCCC !important;
	}

}

.slideshow{
	//padding:0px;
	border:none;
	//padding-top: 10px;
    //padding-bottom: 10px;
    h2{
    	margin-bottom:10px;
    }
    .displayed{
    	display: block;
        margin-right: auto;
        margin-left: auto;
	    text-align:center;
	    margin-bottom:10px;
    }
   
	.upperCorp{
		height:130px;
		width:100%;
		margin:auto auto;
		margin-top:30px;
		&.table{
  			    height:130px !important;

		}
	} 
}

.center .slideshow-pager {
	position: absolute;
	bottom: 40px;
	width: 54px;
	height: 56px;
	text-indent: -99999px;
	z-index: 150;
	&#prev {
		background: url('../images/left-scroller.png') no-repeat;
		left: -7px;
	}
	&#next {
		background: url('../images/right-scroller.png') no-repeat;
		right: -7px;
	}	
	.read {
		position: absolute;
		top: 0px;
		left: 0px;
		height: 100%;
		width: 100%;
		z-index: 251;
		display:block;
	}
}
.gallery {
	.caption {
		position: absolute;
		bottom: 0;
		left: 0;
		background: rgba(0, 0, 0, 0.8);
		color: #fff;
		width: 100%;
	}
}

.glyphicon {
	.glyphicon;
}
.glyphicon-list {
	.glyphicon-list;
}

// Ensure we have reasonably tall pages no matter what.
// .icon {
// 	.square(61px);
// 	display: inline-block;
// 	.border-left-radius(50%);
// 	.border-right-radius(50%);
// 	background: #e06900;
// }
#content {

}
span{
	i{
		color:#ffffff;
		margin-left:0px;
	}
}

.media-heading{
	font-size:18px;
}
.summary{
	margin-bottom:10px;
}

/*Home Styeles*/
.home{
	.fisrtWord strong{
		color: #e06900;
	}
	.box{
		h1,h2{
			text-transform:uppercase;
		}
		a{
			color:#e06900;
		}
		h2{
			font-size:18px;
		}
		&.top{margin-top:73px;}
		.about-us{
			margin-top:73px;
			margin-bottom:73px;
			h1{
				font-size:23px;
			}
			p{
				font-size:17px;
			}
		}
		.media{
			.media-heading{
				font-size:18px;
			}
			p{
				font-size:14px;
			}
			.img-responsive{
				margin-top:20px;
			}
			padding:0 0px;
			overflow:hidden;
		}
		.news,.topics,.corp-member{
			h2{
				font-size:20px;
			}
			p{
				font-size:14px;
			}
			h3{
				margin:0;
				a{
					font-size:14px;
					color:#e06900;
					font-weight:bold;
				}
			}
			.read-more{
				display: block;
				line-height:3em;
			}
		}
		.corp-member{text-align:center;}
		.news,.topics{
			padding-bottom:10px;
			border-bottom:1px solid #9c9c9c;
		}
		&.third{
			margin-top:76px;
			margin-bottom:76px;
		}
		.view-all{
			margin-top:30px;
			a{
				color:#ffffff;
				background-color: #e06900;
				font-size:12px;
				padding:3px 7px;
				border-radius:4px;
			}
			.icon-vall{
				padding-right:5px;
			}
		}
	}
}
.form-group{
	// .form-control{
	// 	background:#f2f2f2;
	// 	border:none;
	// 	border-radius:0;
	// }
	// .btn{
	// 	float:right;
	// 	color:#ffffff;
	// 	background-color:#e06900;
	// 	border-radius:0;
	// 	padding:10px 20px;
	// }
}

// .form-group .btn {
// 	float: right;
// 	color: #ffffff;
// 	background-color: #e06900;
// 	border-radius: 0;
// 	border:none;
// 	&.search-btn{padding: 3px 5px}
// }


// .input-group-btn > .btn {
// 	position: absolute;
// 	bottom: 0px;
// 	left: 191px;
// }

.search-box {
	position: absolute;
	top: 0px;
	right: 0px;

	.search-box-activate {
		background: url('../images/new-search-icon.png') center center no-repeat;
		height: 26px;
		width: 26px;
		z-index:150;
		margin-top:6px;
	}
}

.search-box-form {
	display:none;

	&.active {
		display:block;
		background:#d2d2d2;
		position:absolute;
		right:0;
		bottom: -54px;
		height: 52px;
		input {
			// height:80px;
			// width:400px;
		}

	button[type=submit] {
			// height:80px;
			// color:black;
		}
	}
}

/*Page content*/
.page-headerimage{
	margin-top:80px;
	margin-bottom:80px;
}
.page-heading{
	margin-top:80px;
	h1{
		color:#333333;
		text-transform:uppercase;
		font-size:24px;
		font-weight:700;
		margin-bottom:20px;
	}
}
.content-page{
	.article-content{
		li{
			color:#666666;
			font-size:13px;
			margin-bottom:15px;
		}
	}
}

// any article images mobile (xs) do not have floats and scale 100%)

@media (max-width: @screen-xs-max) {

	.article-image {
		&.float-left {float:none; margin:0}
		&.float-right {float:none; margin:0}
	}

	.image-group-grid {
		.img {
			float:none;
			margin:0;
			padding:0 !important;
		}
	}
	.navbar-default .navbar-collapse {
		max-height: none;
	}
	.home .box.top {
		margin-top: 40px;
		.media{margin-bottom:20px;}
	}
	.home .box .about-us {margin-top: 20px;margin-bottom: 20px;}
}
/* Extra small devices (phones, less than 768px) */
/* No media query since this is the default in Bootstrap */

@media (max-width: @screen-xs){
	.navbar-default .navbar-nav .open .dropdown-menu > li > a {
	color: #ffffff;
	}
	.navbar-default .navbar-nav > .open > a, .navbar-default .navbar-nav > .open > a:hover, .navbar-default .navbar-nav > .open > a:focus {
		color: #ffffff;
	}
}
/* Small devices (tablets, 768px and up) */
@media (min-width: @screen-sm) {
	.nav > li > a {
		padding: 7px 10px;
	}
}

/* Medium devices (desktops, 992px and up) */
@media (min-width: @screen-md) {
	.navbar-collapse {
		padding-right: 0px;
		padding-left: 0px;
	}
	.round-logo{
		position: absolute;
		top: 25%;
        left: -9%;
	}
	.nav > li > a {
		padding: 10px 13px;
	}
}

/* Large devices (large desktops, 1200px and up) */
@media (min-width: @screen-lg) {
	// .page-banner{
	// 	.round-logo{
	// 		position:absolute;
	// 		top:2%;
	// 		left:6%;
	// 	}
	// }
}

// Popovers extended
.popover-list {
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: @font-size-small;

  > li > a {
    color: @gray;
    padding-bottom: 2px;
    margin-bottom: 3px;
    display: inline-block;
    border-bottom: 1px solid;

    &, &:hover {
      text-decoration: none;
    }
    &:hover {
      border-color: @brand-primary;
    }
  }
}

.popover-inner {
  min-width: 300px;
}

path {
  cursor: pointer;
}
path.active {
  fill: @brand-primary;
}

path.pop-up {
  fill: lighten(@brand-primary, 30%);
  &:hover {
    fill: lighten(@brand-primary, 15%);
  }
}

/* Padding for spacing */
.inner {
  padding: 30px;
}

.inner.cover {
  padding-top: 170px;
  padding-bottom: 170px;
  margin-bottom: -170px;
  text-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
}

.cover-text {
  font-size: @font-size-base;
  font-weight: 300;

  @media (min-width: @screen-sm-min) {
    font-size: @font-size-large
  }

}

.section.map {
	position:relative;
	display:block;
}

.popover {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1041;
    display: none;
    max-width: 276px;
    padding: 1px;
    font-family: Lato,sans-serif;
    font-style: normal;
    font-weight: 400;
    letter-spacing: normal;
    line-break: auto;
    line-height: 1.42857143;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    white-space: normal;
    word-break: normal;
    word-spacing: normal;
    word-wrap: normal;
    font-size: 16px;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ccc;
    border: 1px solid rgba(0,0,0,.15);
    border-radius: 6px;
    box-shadow: 0 5px 10px rgba(0,0,0,.2);
}

.popover.top {
    margin-top: -10px;
}

section.taskforce-tabs {
	ul.nav.nav-tabs {
		background: #f4f4f4;
		li {
    		background: #f4f4f4;
    		margin-bottom:0px;
    		&.active {
    			a {
    				border:none;
    				background: #dcdcdc;
    				color:@brand-primary;
    			}
    		}
			a {
				font-weight:bold;
				border:none;
				margin-right:0px;
				&:hover, &:active {
					background: #dcdcdc;
				}
			}
		}
	}
}